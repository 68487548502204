import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AssessmentComponent} from './assessment/assessment.component';
import {AssessmentsService} from './assessments.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import {UnansweredQuestion} from './unansweredQuestionPipe';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {BreedsService} from "./breeds.service";
import { MatFormFieldModule, MatSelectModule } from '@angular/material';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";

@NgModule({
  declarations: [
    AppComponent,
    AssessmentComponent,
    UnansweredQuestion
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot(),
    MatSelectModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    BrowserAnimationsModule
  ],
  providers: [AssessmentsService, BreedsService],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(translateService: TranslateService) {
    translateService.setDefaultLang('en');

    translateService.setTranslation('en', {
      firstName: 'first name',
      lastName: 'last name',
      red: 'red',
      blue: 'blue',
      green: 'green',
      yellow: 'yellow',
      yes: 'yes',
      no: 'no',
      fillInTheForm: 'Please fill in the form.',
      yourAnswersAreBeingSend: 'You are answers are being send, please wait.',
      yourAnswersAreSend: 'Your answers are saved, please close this window.',
      previous: 'Previous',
      start: 'Start',
      whatAreYourInsightsColors: 'What are your Insights colors?',
      selectBreed: 'Select breed',
      ageOfDog: 'Age of dog'
    });
    translateService.setTranslation('nl', {
      firstName: 'voornaam',
      lastName: 'achternaam',
      red: 'rood',
      blue: 'blauw',
      green: 'groen',
      yellow: 'geel',
      yes: 'ja',
      no: 'nee',
      fillInTheForm: 'Vul het volledig formulier in.',
      yourAnswersAreBeingSend: 'Je antwoorden worden verzonden, even geduld aub.',
      yourAnswersAreSend: 'Je antwoorden zijn succesvol opgeslagen, gelieve dit venster te sluiten.',
      previous: 'Vorige',
      start: 'Start',
      whatAreYourInsightsColors: 'Wat zijn je Insights kleuren?',
      selectBreed: "Selecteer ras",
      ageOfDog: "Leeftijd van de hond"
    });
  }
}

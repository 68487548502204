import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";


export interface Breed {
  name: string;
}


@Injectable()
export class BreedsService {

  constructor(private http: HttpClient) {
  }


  getBreeds(): Observable<Breed> {
    return this.http.get<Breed>('/api/breeds/');
  }

}
